import { get } from "underscore";

import FR from "./data/fireland.json";

const ITEMDB = {
  ...FR,
};

function getItemById(itemId) {
  return get(ITEMDB, itemId);
}

export { getItemById };
