import * as styled from "../style.module.scss";

import { MenuRow } from "./menuRow";
import clsx from "clsx";
import ky from 'ky';
import { useState, useEffect } from "preact/hooks";
import {sortBy} from 'underscore';

function SideMenu(props) {
  // const { items } = props;
  const [items, setItems] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await ky.get(`https://cdn.jsdelivr.net/gh/c70-dev/gdkp@public/index.json`).json();
      // const data = JSON.parse(res.body);
      setItems(sortBy(data.records, 'date').reverse());
    })();
  }, []);

  return (
    <aside class={clsx("menu", styled.aside)}>
      <p class="menu-label">Loot Records</p>
      <ul class="menu-list">
        {items.map((e) => (
          <MenuRow {...e} key={e.uuid} />
        ))}
      </ul>
    </aside>
  );
}

export { SideMenu };
