import { useState, useEffect } from "preact/hooks";
import { useSignalEffect } from "@preact/signals";
// import "./assets/app.scss";
// import {shuffle } from "underscore";
import { PayoutTable } from "./featues/payoutView";
import { LootTable } from "./featues/lootView";
import { SideMenu } from "./featues/sideMenu";

import clsx from "clsx";
import ky from 'ky';
import {isEmpty} from "underscore";
import { reportID } from "./store/menu";

import * as styled from "./app.module.scss";
// import * as RT from "./out.json";

// const menu = [
//   {
//     date: 1731029046,
//     title: "秦丝",
//     payout: 1111,
//   },
//   {
//     date: 1731079046,
//     title: "开荒，dps不高吃屎",
//     payout: 5004,
//   },
//   {
//     date: 1731049046,
//     title: "Make BoneFire Great Again",
//     payout: 15904,
//   },
// ];

// const loot = {
//   lootData: RT.items,
//   playerDB: RT.players,
//   payoutData: RT.ledgers,
// };



export function App() {
  const [mode, setMode] = useState("LOOT");
  const [lootData, setLootData] = useState({});

  const setTargetMode = (m) => {
    if (m == mode) return;

    switch (mode) {
      case "LOOT":
      case "PAYOUT":
        setMode(m);
        break;
    }
  };

  async function loadReport(id) {
    const data = await ky.get(`https://cdn.jsdelivr.net/gh/c70-dev/gdkp@public/records/${id}.json`).json();
    // return res;
    // const data = json.parse(res);
    // console.log(data);
    setLootData({
      lootData: data.items,
      playerDB: data.players,
      payoutData: data.ledgers
    });
  }
  // load report centent.
  useSignalEffect(() => {
    // console.log("New reportID: ", reportID.value);
    if (reportID.value == "") { return; }
    loadReport(reportID.value);
  });

  return (
    <>
      <div
        class="container is-max-desktop"
        style="margin-top: 6rem; width: 600px;"
      >
      {!isEmpty(lootData) && (
        <>
        <div class={clsx(styled.tabs)}>
          <ul>
            <li
              class={clsx({ ["is-active"]: mode == "LOOT" })}
              onClick={() => setTargetMode("LOOT")}
            >
              <span class="material-icons">receipt</span>
            </li>
            <li
              class={clsx({ ["is-active"]: mode == "PAYOUT" })}
              onClick={() => setTargetMode("PAYOUT")}
            >
              <span class="material-icons">payments</span>
            </li>
          </ul>
        </div>
        <div
          class={clsx(styled.container)}>
          <LootTable {...lootData} isShow = {mode == "LOOT"} />
          <PayoutTable {...lootData} isShow = {mode == "PAYOUT"} />
        </div>
      </>)}
      </div>
      <SideMenu />
    </>
  );
}
