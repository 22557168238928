@use "/src/_scss/consts/wow" as w;
@use "/src/_scss/consts/var" as g;
@use "sass:map";
@use "sass:color";

.item-row {
  /* background: #1c1c1e; */
  background: g.$fill3;
  font-family: Oxanium;
  &:hover {
    background: g.$fill1;
  }

  :global(.column.is-2) {
    width: 20%;
    padding: 0.5rem;
  }
}

:global(.column).item {
  display: flex;
  align-items: center;

  padding: 0.5rem;
  width: 40%;

  :global(img) {
    outline: 0.0625rem solid g.$opaqueSeparator;
  }
  :global(.image) {
    place-self: center;
    grid-area: icon;
  }
  span {
    margin-left: 0.5rem;
  }
}

.gcard {
  display: grid !important;
  grid-template-columns: 2.25rem auto;
  grid-template-rows: 1.25rem 1rem;
  column-gap: 0.25rem;
  justify-content: end;
  grid-template-areas:
    "gold gold"
    "icon info";

  :global(.gold){
    font-size: 1rem;
    grid-area: gold;
    margin-left: auto;
    color: g.$gray4;
    font-weight: 600;
  }

  :global(.info){
    font-size: 0.875rem;
    grid-area: info;
  }
}

.player {
  font-weight: 600;
  // font-size: 1.125rem;
  font-size: 1rem;
  padding-top: 0.125rem;
}

@each $q, $c in w.$wow-class {
  .is-#{$q} {
    color: $c;
  }
}

.payout {
  color: g.$gray4;
  padding-top:0.125rem;
  font-weight: 600;
  font-size: 1.125rem;
  min-width: 4.5rem;
  // background: g.$fill3;
  padding-right: 0.5rem;
}

.payout-icon {
  margin-right: 0.5rem;
  img {
    content: url(w.$coin_01);
  }

  &:global(.is-mailed) img{
    content: url(w.$mail_01);
  }
}
