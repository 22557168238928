import clsx from "clsx";
import { contains, filter, now } from "underscore";

import { LOOT_ITEM_TYPE } from "/src/const";
import { getItemById } from "/src/helper/wowItemDB";
import { useMemo } from "preact/hooks";

import { ItemRow } from "./itemRow";

function buildLootRecords(lootData, playerDB) {
  const results = [];
  for (const record of lootData) {
    // console.log(record);
    let r = {
      id: record.uuid,
      amount: record.amount,
      playerInfo: playerDB[record.playerId]
    };

    switch (record.itemType) {
      case LOOT_ITEM_TYPE.BOE:
      case LOOT_ITEM_TYPE.BOP:
        let item = getItemById(record.itemID);
        // console.log(record.uuid, record.itemID, item);
        if (item == null) {
          r.itemData = {
            icon: "https://cros-wow.c70.ca/images/wow/icons/medium/inv_misc_summerfest_brazierblue.jpg",
            text: "未录入物品",
            subtext: record.itemID,
            q: 6,
          };
          break;
        }
        r.itemData = {
          icon: `https://cros-wow.c70.ca/images/wow/icons/medium/${item.p}.jpg`,
          text: item.z,
          subtext: item.e,
          q: item.q,
          heroic: item.h,
          item_id: item.i,
          item_ilvl: item.l,
          boe: item.b == LOOT_ITEM_TYPE.BOE,
        };
        break;
      case LOOT_ITEM_TYPE.FINE:
        r.itemData = {
          icon: "https://cros-wow.c70.ca/images/wow/icons/medium/inv_misc_summerfest_brazierred.jpg",
          text: "罚款",
          subtext: "Fine",
          q: 9,
        };
        break;
      case LOOT_ITEM_TYPE.GOLD:
        r.itemData = {
          icon: "https://cros-wow.c70.ca/images/wow/icons/medium/inv_misc_summerfest_brazierorange.jpg",
          text: "杂项",
          subtext: record.note,
          q: 6,
        };
        break;
    }

    results.push(r);
  }
  return results;
}

function LootTable(props) {
  const { lootData, playerDB, isShow} = props;
  // const [renderData, setRenderData] = useState({});
  /*
        lootData = [...]
        {
            playerId: xxxx,
            itemId: xxxx,
            itemType: [...],
            amount,
        }
    */

  const lootRecords = useMemo(() => {
    // build LootRecords
    const data = buildLootRecords(lootData, playerDB);
    return data;
    // setRenderData(data);
  }, [lootData, playerDB]);

  return (
    <div class={clsx({["is-hidden"]: !isShow})}>
      {lootRecords.map((data) => (
        <ItemRow {...data} key={data.id} />
      ))}
    </div>
  );
}

export { LootTable };
