import clsx from "clsx";
import { contains, filter, now } from "underscore";

import { useMemo } from "preact/hooks";
import { ItemRow } from "./itemRow";

function buildPayoutRecords(payoutData, playerDB) {
  const results = [];
  for (const record of payoutData) {
    // console.log(record);
    const playerInfo = playerDB[record.playerID];
    let r = {
      cut: record.cut,
      paid: record.paid,
      payout: record.given > 0 ? record.given : record.mailed,
      isMailed: record.mailed > 0,
      playerInfo: playerInfo,
      icon: `https://cros-wow.c70.ca/images/wow/icons/medium/classicon_${playerInfo.class}.jpg`
    };
    if (playerInfo.class == "") {
      r.icon = `https://cros-wow.c70.ca/images/wow/icons/medium/classic_temp.jpg`;
    }
    // console.log(r);
    results.push(r);
  }
  return results;
}

function PayoutTable(props) {
  const { payoutData, playerDB, isShow } = props;

  const payoutRecords = useMemo(() => {
    const data = buildPayoutRecords(payoutData, playerDB);
    return data;
  }, [payoutData, playerDB]);

  return (
    <div class={clsx({["is-hidden"]: !isShow})}>
      {payoutRecords.map((data, i) => (
        <ItemRow {...data} key={i} />
      ))}
    </div>
  );
}

export { PayoutTable };
