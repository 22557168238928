@use "/src/_scss/consts/var" as g;

.container {
    padding: 0 1rem;
    max-height: 80vh;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
        // scrollbar-width: thin;
        // scrollbar-color: g.$opaqueSeparator rgba(0, 0, 0, 255);
    }
}

.tabs {
    position: absolute;
    top: 1rem;
    right: -3.5rem;
    z-index: 1;

    ul {
        display: flex;
        flex-direction: column;
    }

    :global(span.material-icons) {
        font-size: 2rem;
    }

    li > span{
        padding: 0.5rem 0.5rem;
        border-radius: 0;
        border: none;
        cursor: pointer;

        background: g.$fill3;
        &:hover {
            color: g.$label0;
            background: g.$fill0;
        }
    }

    :global(li.is-active) {
        color: g.$label0;
        background: #3846759e;
    }
}