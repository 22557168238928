@use "/src/_scss/consts/wow" as w;
@use "/src/_scss/consts/var" as g;
@use "sass:color";

aside.aside {
    right: 0;
    top: 0;
    position: fixed;
    z-index: 11;
    width: 15rem;
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;

    li {
        background: g.$fill3;
        padding: 0.5rem 0.75rem;
        padding-right: 0.375rem;
        &:hover {
            background: g.$fill0;
        }
        &:global(.active) {
            background: #3846759e;
        }
    }

    :global(ul.menu-list) {
        height: 90vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.menu-item {
    display: grid;
    cursor: pointer;
    grid-template-columns: auto 3rem;
    grid-template-rows: minmax(max-content, 1.25rem) 1rem;
    column-gap: 0.25rem;
    grid-template-areas:
        "title payout"
        "date payout";

    :global(.title) {
        font-size: 1rem;
        grid-area: title;
    }

    :global(.subtitle) {
        grid-area: date;
        font-size: 0.875rem;
    }

    :global(.payout) {
        grid-area: payout;
        max-width: 3rem;
        color: w.$gold;
        font-family: EB;
        font-weight: 600;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            width: 0.875rem;
            height: 0.875rem;
            fill: w.$gold;
        }
    }
}
