import * as styled from "../style.module.scss";

import { reportID, setReportID } from "/src/store/menu";

import clsx from "clsx";
import format from 'date-fns/format';
import { formatters, longFormatters } from "date-fns";
const FORCE_BUNDLE = [formatters, longFormatters];

const k_svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M311 86.3c12.3-12.7 12-32.9-.7-45.2s-32.9-12-45.2 .7l-155.2 160L64 249 64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 328 0 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-107 64.7-66.7 133 192c10.1 14.5 30 18.1 44.5 8.1s18.1-30 8.1-44.5L174.1 227.4 311 86.3z"/></svg>';

function MenuRow(props) {
  const { title, date, payout, uuid } = props;
  // console.log(props);
  return (
    <li class={clsx("mb-3", uuid == reportID.value && "active")}>
      <div
      onclick={() => {
        // console.log("setuuid", uuid);
        setReportID(uuid);
      }}
      class={clsx(styled["menu-item"])}>
        <span class="title">{title}</span>
        <span class="subtitle">
          {format(new Date(date * 1000), "LLL d, yyyy")}
        </span>
        <span class="payout">
          <span>{(payout / 1000).toFixed(1)}</span>
          <span class={clsx("ml-1 mt-1")}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M311 86.3c12.3-12.7 12-32.9-.7-45.2s-32.9-12-45.2 .7l-155.2 160L64 249 64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 328 0 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-107 64.7-66.7 133 192c10.1 14.5 30 18.1 44.5 8.1s18.1-30 8.1-44.5L174.1 227.4 311 86.3z" />
            </svg>
          </span>
        </span>
      </div>
    </li>
  );
}

export { MenuRow };
