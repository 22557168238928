import * as styled from "../style.module.scss";

import clsx from "clsx";
import { contains, filter, now } from "underscore";
// import { useState } from "preact/hooks";
// https://cdn-z3.c70.ca/icons/inv_enchant_disenchant.webp
// https://cdn-z3.c70.ca/icons/inv_misc_coin_17.webp
//

function ItemRow(props) {
  const { cut, playerInfo, payout, paid, isMailed, icon } = props;

  return (
    <div
      class={clsx(
        "columns",
        "my-2",
        "is-align-items-center",
        styled["item-row"],
      )}
    >
      <div
        class={clsx(
          "column",
          styled.item
        )}
      >
        <figure class="image is-32x32">
          <img src={icon} />
        </figure>
        <span class={clsx(styled.player, styled[`is-${playerInfo.class}`])}>
          {playerInfo.name}
        </span>
      </div>
      <div class={clsx("column is-2", styled.gcard)}>
          <span class="gold">
            {paid}
          </span>
          <span class="info">
            Spending
          </span>
      </div>
      <div class={clsx("column is-2", styled.gcard)}>
          <span class="gold">
            {cut}
          </span>
          <span class="info">
            Cuts
          </span>
      </div>
      <div class="column is-3 is-justify-content-end is-flex is-align-items-center">
        <figure class={clsx("image is-24x24", styled["payout-icon"], isMailed && "is-mailed" )}>
          <img />
        </figure>
        <span class={clsx(styled.payout, "has-text-right")}>{payout}</span>
      </div>
    </div>
  );
}

export { ItemRow };
