@use "/src/_scss/consts/wow" as w;
@use "/src/_scss/consts/var" as g;
@use "sass:map";
@use "sass:color";

.item-row {
  /* background: #1c1c1e; */
  background: g.$fill3;
  font-family: Oxanium;
  &:hover {
    background: g.$fill1;
  }
}

.is-boe {
  figure::after {
    content: "BoE";
    color: g.$gray5;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: bold;
    line-height: 1;
    font-size: 0.75rem;
    text-align: center;
    text-shadow: #111 1px 0 10px;
  }
  :global(.title::after) {
    content: " [BoE]";
    color: map.get(w.$quality, "3");
    font-family: Oxanium;
    font-weight: bold;
  }
}

:global(.column).item {
  display: grid;
  grid-template-columns: 2.25rem auto;
  grid-template-rows: 1.25rem 1rem;
  column-gap: 0.25rem;
  grid-template-areas:
    "icon title"
    "icon subtitle";
  padding: 0.5rem;

  :global(img) {
    outline: 0.0625rem solid g.$opaqueSeparator;
    @at-root :global(.is-heroic)#{&} {
      outline: 0.0625rem solid w.$heroic;
    }
  }
  :global(.image) {
    place-self: center;
    grid-area: icon;
  }
  :global(.title) {
    font-size: 1rem;
    grid-area: title;
  }
  :global(.title::after) {
    @at-root :global(.is-heroic)#{&} {
      content: "[H] ";
      color: w.$heroic;
      font-family: Oxanium;
      font-weight: bold;
    }
  }

  :global(.subtitle) {
    grid-area: subtitle;
    font-size: 0.875rem;
    font-weight: 600;
    color: transparent;
    width: fit-content;
    background: linear-gradient(
        to right,
        color.adjust(g.$label1, $lightness: -10%),
        color.adjust(g.$label1, $lightness: 15%)
      )
      text;
  }
}

@each $q, $c in w.$quality {
  .is-quality-#{$q} {
    :global(.title) {
      /* color: $c; */
      background: linear-gradient(
        to right,
        color.adjust($c, $lightness: -10%),
        color.adjust($c, $saturation: 15%)
      );
      background-clip: text;
      color: transparent;
      width: fit-content;
    }
  }
}

.player {
  font-weight: 600;
  // font-size: 1.125rem;
  font-size: 1rem;
  padding-top: 0.125rem;
}

@each $q, $c in w.$wow-class {
  .is-#{$q} {
    color: $c;
  }
}

.money {
  color: g.$gray4;
  padding-top:0.125rem;
  // font-family: EB;
  font-weight: 500;
  // font-size: 1.25rem;
  font-size: 1rem;
  min-width: 5rem;
  background: g.$fill3;
  padding-right: 0.5rem;
}

.money-icon {
  margin-right: 0.5rem;
  img {
    content: url(w.$coin_01);
  }
}
