import * as styled from "../style.module.scss";

import clsx from "clsx";
import { contains, filter, now } from "underscore";
// import { useState } from "preact/hooks";
// https://cdn-z3.c70.ca/icons/inv_enchant_disenchant.webp
// https://cdn-z3.c70.ca/icons/inv_misc_coin_17.webp
//

function ItemRow(props) {
  const { itemData, playerInfo, amount } = props;

  return (
    <div
      class={clsx(
        "columns",
        "my-2",
        "is-align-items-center",
        styled["item-row"],
      )}
    >
      <div
        class={clsx(
          "column",
          styled.item,
          styled[`is-quality-${itemData.q}`],
          itemData.heroic && "is-heroic",
          itemData.boe && styled["is-boe"],
        )}
      >
        <figure class="image is-32x32">
          <img src={itemData.icon} />
        </figure>
        <span class="title">{itemData.text}</span>
        <span class="subtitle">{itemData.subtext}</span>
      </div>
      <div class="column is-3 is-hidden-mobile is-flex is-justify-content-center">
        <span class={clsx(styled.player, styled[`is-${playerInfo.class}`])}>
          {playerInfo.name}
        </span>
      </div>
      <div class="column is-3 is-justify-content-end is-flex is-align-items-center">
        <figure class={clsx("image is-24x24", styled["money-icon"])}>
          <img />
        </figure>
        <span class={clsx(styled.money, "has-text-right")}>{amount}</span>
      </div>
    </div>
  );
}

export { ItemRow };
